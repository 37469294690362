import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { SampleImages, SampleImagesClear } from '../utils/Constant';

const SampleStyle = ({onImageChange}) => {
  const handleSampleImageClick = (index) => {
    const imageClear = SampleImagesClear[index];
    onImageChange(imageClear, false);
  };

  return (
      <Box mt={5} style={{ marginTop: '90px'}}>
      <Typography variant="h6" style={{ 
          color: 'rgb(69, 69, 69)', 
          marginBottom: '16px',
          fontSize: '1.075rem',
          lineHeight: '2.25rem',
      }}>
      试试这些图片:
      </Typography>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {SampleImages.map((sample, index) => (
          <Grid item key={index}>
            <img
              src={sample}
              alt={`Sample ${index + 1}`}
              style={{ width: '64px', height: '64px', cursor: 'pointer', borderRadius: '8px' }}
              onClick={() => handleSampleImageClick(index)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SampleStyle;