import React, { useRef, useState, useEffect } from 'react';
import { Button } from '../components/ui/button';
import { downloadedImageName } from '../utils/Utils';
import Blur from 'react-css-blur';
import { Slider } from '../components/ui/slider';
import '../css/fonts.css';

const BlurBackgroundPage = () => {
  const [removedBgImageUrl, setRemovedBgImageUrl] = useState(null);
  const fileInputRef = useRef(null);
  const [blurRadius, setBlurRadius] = useState(5); // 添加模糊半径状态
  const blurredImageRef = useRef(null); // 添加对模糊图片的引用
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleUploadImage = () => {
      if (fileInputRef.current) {
          fileInputRef.current.click();
      }
  };

  const handleFileChange = async (event) => {
      const file = event.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setRemovedBgImageUrl(reader.result);
        };

        await uploadImage(file);
      }
  };

  const uploadImage = async (imageFile) => {
    // Create form data with the image file
    const formData = new FormData();
    formData.append('image', imageFile);
  
    try {
        // Upload image to server
        await fetch('/api/blur-image', {
            method: 'POST',
            body: formData,
        });
    } catch (error) {
        // Handle errors
        console.error('Error uploading image:', error);
    } finally {
    }
  };     

  // 修改下载函数
  const handleDownloadImage = () => {
    if (!removedBgImageUrl || !blurredImageRef.current) return;
     // 创建canvas来捕获模糊后的图片
    const canvas = document.createElement('canvas');
    const blurredImg = blurredImageRef.current;
    canvas.width = blurredImg.offsetWidth;
    canvas.height = blurredImg.offsetHeight;
    
    const ctx = canvas.getContext('2d');
    ctx.filter = `blur(${blurRadius}px)`;
    
    const img = new Image();
    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      
      // 转换为blob并下载
      canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = downloadedImageName() + '.png';
        link.href = url;
        link.click();
        URL.revokeObjectURL(url);
      }, 'image/png');
    };
    img.src = removedBgImageUrl;
  };
 

    return (
        <div className='flex flex-col h-screen bg-white'>
            <div className='flex flex-col md:flex-row items-center justify-center gap-10 w-full h-screen px-10 mt-16' style={{ alignItems: 'start'}}>
                <div className="flex flex-col items-center justify-start w-full md:w-1/2 gap-4">
                    <div className='flex gap-4 items-center justify-start'>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            accept=".jpg, .jpeg, .png"
                        />
                        <Button onClick={handleUploadImage}>
                            上传图片
                        </Button>
                        <Button onClick={handleDownloadImage}>
                            下载图片
                        </Button>
                    </div>
                    
                    <div className="w-[80%] flex flex-col gap-2">
           <label className="text-sm">模糊程度: {blurRadius}px</label>
           <Slider
             value={[blurRadius]}
             onValueChange={([value]) => setBlurRadius(value)}
             min={0}
             max={20}
             step={1}
           />
         </div>
          <div className="min-h-[400px] w-[80%] p-4 border border-border rounded-lg relative overflow-hidden flex items-center justify-center bg-white">
           <Blur radius={`${blurRadius}px`} transition="400ms">
             <img
               ref={blurredImageRef}
               src={removedBgImageUrl}
               alt="Uploaded"
               layout="fill"
               objectFit="contain" 
               objectPosition="center" 
             />
           </Blur>
         </div>
       </div>
     </div>
   </div>
    );
};

export default BlurBackgroundPage;