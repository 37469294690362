import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const GlobalPasteHandler = () => {
  const navigate = useNavigate();

  const navigateToPageDetail = (imageUrl) => {
    navigate('/replace-backgroundcolor', { state: { imageFile: imageUrl, requested: true } });
  };

  const downloadImage = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      if (!response.ok) {
        throw new Error('Failed to download image');
      }
      const blob = await response.blob();
      // const downloadedImageUrl = URL.createObjectURL(blob);
      navigateToPageDetail(blob);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  useEffect(() => {
    const handlePaste = async (event) => {
      const items = event.clipboardData.items;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.type.indexOf('image') !== -1) {
          // Pasted content is an image file
          const imageFile = item.getAsFile();
          const imageUrl = URL.createObjectURL(imageFile);
          navigateToPageDetail(imageUrl);
        } else if (item.type === 'text/plain') {
          // Pasted content is a plain text
          const text = await getTextFromClipboard(item);
          if (isValidImageUrl(text)) {
            // If pasted text is a valid image URL, download the image and navigate to Page B
            downloadImage(text);
          }
        }
      }
    };

    window.addEventListener('paste', handlePaste);

    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, []);

  const isValidImageUrl = (url) => {
    // Simple validation of URL to check if it starts with 'http' or 'https'
    return url.startsWith('http://') || url.startsWith('https://');
  };

  const getTextFromClipboard = async (item) => {
    if (item.kind === 'file') {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => resolve(event.target.result);
        reader.onerror = (error) => reject(error);
        reader.readAsText(item.getAsFile());
      });
    } else if (item.kind === 'string') {
      return new Promise((resolve, reject) => {
        item.getAsString((text) => {
          if (typeof text === 'string') {
            resolve(text);
          } else {
            reject(new Error('Failed to get string from clipboard'));
          }
        });
      });
    } else {
      throw new Error('Unsupported clipboard item type');
    }
  }

  return null;
};

export default GlobalPasteHandler;
