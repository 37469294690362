import React from 'react';
import '../css/License.css';

export const HuanbjtLicense = () => {
  return (
    <div style={{ position: 'relative', left: 0, bottom: 0, width: '100%', background: 'transparent', textAlign: 'center', padding: '10px' }}>
	    <p>
      		<a className="text-color" href="https://beian.mps.gov.cn/#/query/webSearch?code=13042002000653" rel="noreferrer" target="_blank">冀公网安备13042002000653</a>
        	<a className="text-color" style={{marginLeft: '12px'}} href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">冀ICP备2024073644号-1</a>
			<a className="text-color" style={{marginLeft: '12px'}} target="_blank" rel="noreferrer">Copyright 2024 © 幻图妙盒</a>
		</p>
    </div>
  );
};