import React from 'react';
import { Container, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UploadBox from '../components/UploadBox';
import SampleStyle from '../components/SampleStyple';
import SlogoStyle from '../components/Slogo';
import Footer from '../components/Footer';
import '../css/HomePage.css';
import '../css/globals.css';

const HomePage = () => {
  const navigate = useNavigate();
  const navigateToRemoveBgPage = (imageFile, requested) => {
    // imageFile: 上传的图片
    // requested: 是否需要请求网络
    navigate('/replace-backgroundcolor', { state: { imageFile, requested } });
  }

  return (
    <div className="app-root bg-white">
      <Container maxWidth="lg" style={{ marginTop: '132px', textAlign: 'center', overflow: 'hidden' }}>
        <Grid container spacing={3} justifyContent="center" alignItems="center" marginTop='20px'>
          <SlogoStyle />
          <Grid item xs={12} md={6}>
            <UploadBox onImageChange={navigateToRemoveBgPage} />
          </Grid>
        </Grid>
        <SampleStyle onImageChange={navigateToRemoveBgPage}/>
      </Container>

      <Footer />
    </div>
  );
};

export default HomePage;
