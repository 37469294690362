import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import DragAndDropOverlay from './components/DragAndDropOverlay';
import GlobalPasteHandler from './components/GlobalPasteHandler';
import ReplaceBackgroundColorPage from './pages/ReplaceBackgroundColorPage';
import AddBackgroundTextPage from './pages/AddBackgroundTextPage';
import RemoveBackgroundPage from './pages/RemoveBackgroundPage';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import BlurBackgroundPage from './pages/BlurBackgroundPage';
import './css/App.css';

function App() {
  return (
    <Router>
      <DragAndDropOverlay />
      <GlobalPasteHandler />
      <Header />
      
      <Routes>
        {/* 首页 */}
        <Route path="/" element={<HomePage />} />

        {/* 基础功能 */}
        <Route path="/replace-backgroundcolor" element={<ReplaceBackgroundColorPage />} />

        {/* 高级功能 */}
        {/* 消除背景 */}
        <Route path="/remove-background" element={<RemoveBackgroundPage />} />

        {/* 添加背景文字 */}
        <Route path="/add-background-text" element={<AddBackgroundTextPage />} />

        {/* 模糊背景 */}
        <Route path="/blur-background" element={<BlurBackgroundPage />} />

        {/* 替换背景图片 */}
        {/* <Route path="/replace-backgrund-image" element={<AddBackgroundTextPage />} /> */}

        {/* 局部擦除 */}
        {/* <Route path="/replace-backgrund-image" element={<AddBackgroundTextPage />} /> */}

        {/* 关于页 */}
        <Route path="/about-us" element={<AboutPage />} />
      </Routes>
    </Router>
  );
}

export default App;
