import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';

const UploadBox = ({onImageChange}) => {
  const handleUploadClick = () => {
    document.getElementById('fileInput').click();
  };

  const UploadImageOnChange = (e) => {
    const newImage = e.target.files[0];
    if (newImage) {
      onImageChange(newImage, true);
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="350px"
      bgcolor="#ffffff"
      boxShadow="0px 5px 20px rgba(0, 0, 0, 0.35)"
      borderRadius="24px"
      textAlign="center"
      p={3}
      style={{
        maxWidth: '500px',
        margin: '0 auto',
        position: 'relative',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        startIcon={<UploadIcon />}
        onClick={handleUploadClick}
        style={{ marginBottom: '20px', fontSize: '1.5rem', fontWeight: '600', padding: '10px 20px', borderRadius: '24px', fontFamily: 'Canva Sans,Open Sans,sans-serif'}}
      >
        上传图片
      </Button>
      <input
        id="fileInput"
        type="file"
        accept="image/*"
        onClick={(event) => {event.target.value = null}}
        onChange={UploadImageOnChange}
        style={{ display: 'none' }}
      />
      <Typography variant="h6" 
        style={{ 
            color: 'rgba(84,97,108, 1)',
            fontWeight: '400',
            fontSize: '1.25rem',
        }}>
        或者拖放一张图片，
      </Typography>
      <Typography style={{
        fontSize: '.75rem',
        color: 'rgba(84,97,108,1)'
      }}>
        粘贴图片或URL
      </Typography>
    </Box>
  );
};

export default UploadBox;
