'use client'

import React from 'react';
// import { Label } from '../ui/label';
import { Input } from '../ui/input';

const InputField = ({
  attribute,
  label,
  currentValue,
  handleAttributeChange
}) => {
  const handleInputChange = (event) => {
    const value = event.target.value;
    handleAttributeChange(attribute, value);
  };

  return (
    <>
      <div className="flex flex-col items-start">
        {/* <Label htmlFor={attribute}>{label}</Label> */}
        <Input
          type="text"
          placeholder='text'
          value={currentValue}
          onChange={handleInputChange}
          className='mt-2'
        />
      </div>
    </>
  );
};

export default InputField;