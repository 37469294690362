import React, { useEffect, useRef, useState } from 'react';
import { ReloadIcon } from '@radix-ui/react-icons';
import { useLocation } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { downloadedImageName } from '../utils/Utils';
import '../css/fonts.css';

const RemoveBackgroundPage = () => {
  const [removedBgImageUrl, setRemovedBgImageUrl] = useState(null);
  const canvasRef = useRef(null);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false); // 新增加载状态

  const location = useLocation();
  let data = location.state; // 获取传递的数据

    useEffect(() => {
        window.scrollTo(0, 0);

        if (data) {
            uploadImage(data);
        }
    }, [data])

  const uploadImage = async (imageFile) => {    
      setIsLoading(true); // 开始加载

      // Create form data with the image file
      const formData = new FormData();
      formData.append('image', imageFile);
    
      try {
        // Upload image to server
        const response = await fetch('/api/remove-bg', {
          method: 'POST',
          body: formData,
        });
    
        // Check if the response status is OK (status in the range 200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        // Convert response to a blob
        const blob = await response.blob();      
        // Create a URL for the blob and set the selected image
        const url = URL.createObjectURL(blob);

        setRemovedBgImageUrl(url);
      } catch (error) {
        // Handle errors
        console.error('Error uploading image:', error);
      } finally {
          setIsLoading(false); // 上传完成，停止加载
      }
    };     

  const handleUploadImage = () => {
      if (fileInputRef.current) {
          fileInputRef.current.click();
      }
  };

  const handleFileChange = async (event) => {
      setRemovedBgImageUrl(null);
      if (location.data) {
          location.data = null;
      }

      const file = event.target.files?.[0];
      if (file) {
          await uploadImage(file);
      }
  };

  const handleDownloadImage = () => {
    if (!removedBgImageUrl) return;

    var link = document.createElement('a');

    const imageName = downloadedImageName();
    link.download = imageName + '.png';

    // Create a URL for the Blob
    link.href = removedBgImageUrl;

    // Append the link to the body
    document.body.appendChild(link);

    // Simulate a click on the link to trigger the download
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };

    return (
        <div className='flex flex-col h-screen bg-white'>
            <div className='flex flex-col md:flex-row items-start justify-center gap-10 w-full h-screen px-10 mt-2'>
                <div className="flex flex-col items-center justify-center w-full md:w-1/2 gap-4 mt-16">
                    <canvas ref={canvasRef} style={{ display: 'none' }} />
                    <div className='flex gap-4 items-center justify-center'>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            accept=".jpg, .jpeg, .png"
                        />
                        <Button onClick={handleUploadImage}>
                            上传图片
                        </Button>
                        <Button onClick={handleDownloadImage}>
                            下载图片
                        </Button>
                    </div>
                    <div className="min-h-[400px] w-[80%] p-4 border border-border rounded-lg relative overflow-hidden flex items-center justify-center bg-white">
                    {isLoading ? ( // 根据加载状态渲染
                            <span className='flex items-center w-full gap-2'>
                                <ReloadIcon className='animate-spin' /> 加载中...
                            </span>
                        ) : removedBgImageUrl ? (
                            <img
                                src={removedBgImageUrl} 
                                alt="Uploaded"
                                layout="fill"
                                objectFit="contain" 
                                objectPosition="center" 
                            />
                        ) : null}
        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RemoveBackgroundPage;