import React from 'react';
import { Typography, Grid } from '@mui/material';

const SlogoStyle = () => {
    return (
      <Grid item xs={12} md={6}>
          <Typography variant="h2" style={{ marginTop: '20px', fontWeight: '600', color: 'rgb(69 69 69)' }}>
            幻图妙盒
          </Typography>
          <Typography variant="h6" style={{ marginTop: '10px', color: 'gray',
              fontSize: '1.55rem',
              lineHeight: '1.55rem',
              fontWeight: '500',
           }}>
            一款基于AI的下一代 <span style={{ 
              color: 'orange', 
              fontWeight: 'bold', 
              fontSize: '1.55rem',
              lineHeight: '1.55rem' 
            }}>图片背景编辑器</span>
          </Typography>
      </Grid>
    );
};

export default SlogoStyle;