import React from 'react';
import InputField from './input-field';
import SliderField from './slider-field';
import ColorPicker from './color-picker';
import FontFamilyPicker from './font-picker'; 
import { Button } from '../ui/button';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion"

const TextCustomizer = ({ textSet, handleAttributeChange, removeTextSet, duplicateTextSet }) => {
    return (
        <AccordionItem value={`item-${textSet.id}`}>
            <AccordionTrigger>{textSet.text}</AccordionTrigger>
            <AccordionContent className='p-1'>
                <InputField
                    attribute="text"
                    label="Text"
                    currentValue={textSet.text}
                    handleAttributeChange={(attribute, value) => handleAttributeChange(textSet.id, attribute, value)}
                />
                <FontFamilyPicker
                    // attribute="字体" 
                    attribute="fontFamily" 
                    currentFont={textSet.fontFamily} 
                    handleAttributeChange={(attribute, value) => handleAttributeChange(textSet.id, attribute, value)}
                /> 
                <div className='flex flex-row items-start justify-start gap-10 w-full'>
                    <ColorPicker
                        attribute="color" 
                        label="字体颜色"
                        // label="Text Color"
                        currentColor={textSet.color} 
                        handleAttributeChange={(attribute, value) => handleAttributeChange(textSet.id, attribute, value)}
                    />
                </div>
                <SliderField
                    attribute="left"
                    label="水平移动"
                    // label="X Position"
                    min={-200}
                    max={200}
                    step={1} 
                    currentValue={textSet.left}
                    handleAttributeChange={(attribute, value) => handleAttributeChange(textSet.id, attribute, value)}
                />
                <SliderField
                    attribute="top"
                    // label="Y Position"
                    label="垂直移动"
                    min={-100}
                    max={100}
                    step={1}
                    currentValue={textSet.top}
                    handleAttributeChange={(attribute, value) => handleAttributeChange(textSet.id, attribute, value)}
                />
                <SliderField
                    attribute="fontSize"
                    // label="Text Size"
                    label="字体大小"
                    min={10} 
                    max={800}
                    step={1}
                    currentValue={textSet.fontSize}
                    handleAttributeChange={(attribute, value) => handleAttributeChange(textSet.id, attribute, value)}
                />
                <SliderField
                    attribute="fontWeight"
                    label="字体粗细"
                    // label="Font Weight"
                    min={100}
                    max={900}
                    step={100}
                    currentValue={textSet.fontWeight}
                    handleAttributeChange={(attribute, value) => handleAttributeChange(textSet.id, attribute, value)}
                />
                <SliderField
                    attribute="opacity"
                    label="不透明度"
                    // label="Text Opacity"
                    min={0}
                    max={1}
                    step={0.01}
                    currentValue={textSet.opacity}
                    handleAttributeChange={(attribute, value) => handleAttributeChange(textSet.id, attribute, value)}
                />
                <SliderField
                    attribute="rotation"
                    label="旋转"
                    // label="Rotation"
                    min={-360}
                    max={360}
                    step={1}
                    currentValue={textSet.rotation}
                    handleAttributeChange={(attribute, value) => handleAttributeChange(textSet.id, attribute, value)}
                />
                <div className="flex flex-row gap-2 my-8">
                    {/* <Button onClick={() => duplicateTextSet(textSet)}>Duplicate Text Set</Button> */}
                    {/* <Button onClick={() => removeTextSet(textSet.id)} variant="destructive">Remove Text Set</Button> */}
                    <Button onClick={() => removeTextSet(textSet.id)} variant="destructive">移除编辑器</Button>
                </div>
            </AccordionContent>
        </AccordionItem>
    );
};

export default TextCustomizer;