import { HUANBJT_IAMGE_NAME_PREFIX } from './Constant';
import download from 'downloadjs';

// Function to generate a random name using the current date as a salt
export function generateRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

  /**
   * 生成加载的 star
   */
  export const generateStars = () => {
    const starElements = [];
    for (let i = 0; i < 50; i++) { // Adjust the number of stars as needed
      const star = {
        id: i,
        top: `${Math.random() * 100}%`,
        left: `${Math.random() * 100}%`,
        animationDelay: `${Math.random() * 3}s`
      };
      starElements.push(star);
    }
    
    return starElements;
  };

/*
 * 计算 image 像素信息
*/
export function getImageDimensions(url, callback) {
  var img = new Image();
  img.onload = function() {
      var dimensions = {
          width: this.naturalWidth,
          height: this.naturalHeight
      };
      callback(null, dimensions);
  };
  img.onerror = function() {
      callback(new Error('Could not load image'));
  };
  img.src = url;
}

export function downloadedImageName() {
  const randomString = generateRandomString(10);
  const imageName = HUANBJT_IAMGE_NAME_PREFIX + randomString;
  return imageName;
}