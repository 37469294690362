import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const DragAndDropOverlay = () => {
  const [dragging, setDragging] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleDragEnter = (event) => {
      event.preventDefault();
      setDragging(true);
    };

    const handleDragLeave = (event) => {
      event.preventDefault();
      setDragging(false);
    };

    const handleDragOver = (event) => {
      event.preventDefault();
    };

    const handleDrop = (event) => {
      event.preventDefault();
      setDragging(false);
      const files = event.dataTransfer.files;
      if (files && files.length > 0) {
        const imageFile = files[0];
        if (imageFile.type.startsWith('image/')) {
          const imageUrl = URL.createObjectURL(imageFile);
          navigate('/replace-backgroundcolor', { state: { imageFile: imageFile, requested: true } });
        }
      }
    };

    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('drop', handleDrop);

    return () => {
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('drop', handleDrop);
    };
  }, [navigate]);

  if (!dragging) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(133,110,51, 0.7)',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none',
      }}
    >
      <div
        style={{
        //   pointerEvents: 'all',
          padding: '20px',
          backgroundColor: 'transparent',
        //   borderRadius: '10px',
        //   border: '2px dashed gray',
          fontWeight: '600',
          fontSize: '64px',
          color: 'white',
        }}
      >
        <p>图片拖放到这里</p>
      </div>
    </div>
  );
};

export default DragAndDropOverlay;
