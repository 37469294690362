import React, { useState, useRef, useEffect } from 'react';
import StarLoading from '../components/StarLoading';
import { useLocation } from 'react-router-dom';
import { downloadedImageName, generateStars, getImageDimensions } from '../utils/Utils';
import { Button } from '../components/ui/button';
import '../css/replaceBackgroundColor.css';

const ReplaceBackgroundColorPage = () => {
  const location = useLocation();
  const { imageFile, requested } = location.state || {};
  const [imagePixelInfo, setImagePixelInfo] = useState();

  const [selectedImage, setSelectedImage] = useState();
  const [backgroundColor, setBackgroundColor] = useState('transparent');
  const imageRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [stars, setStars] = useState([]);
  const [hexInput, setHexInput] = useState('');

  useEffect(() => {
    const onMount = () => {
      // 点击上传【控件】的事件
      // 都有数值
      if (imageFile && requested) {
        handleUploadImage(imageFile);
      } else if (imageFile && !requested) {
        // 点击 SampleStyle 控件过来的
        // imageFile -> NON-NULL
        // requested -> false
        setSelectedImage(imageFile);

        // 获取Image的像素信息
        getImageDimensions(imageFile, function(error, dimensions) {
          if (error) {
              console.error(error);
          } else {
            setImagePixelInfo(dimensions);
          }
        });
      } else if (!imageFile && !requested){
        // HBottomBar 过来的
        // 都是 null
      }
      else { 
        // 从 HToolbar 过来的直接使用 DEFAULT_IMAGE
        // setSelectedImage(DEFAULT_IMAGE);
      }
    };
    
    onMount();
  }, [imageFile, requested]);

  /**
   * 上传图片事件  
   */ 
  const handleUploadImage = async (imageFile) => {
    // Start loading
    setLoading(true);
    setStars(generateStars());
  
    // Create form data with the image file
    const formData = new FormData();
    formData.append('image', imageFile);
  
    try {
      // Upload image to server
      const response = await fetch('/api/remove-bg', {
        method: 'POST',
        body: formData,
      });
  
      // Check if the response status is OK (status in the range 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      // Convert response to a blob
      const blob = await response.blob();
  
      // End loading
      setLoading(false);
      // Clear stars after upload
      setStars([]);
  
      // Create a URL for the blob and set the selected image
      const url = URL.createObjectURL(blob);
      setSelectedImage(url);

      // 获取Image的像素信息
      getImageDimensions(url, function(error, dimensions) {
        if (error) {
            console.error(error);
        } else {
          setImagePixelInfo(dimensions);
        }
      });  
    } catch (error) {
      // Handle errors
      console.error('Error uploading image:', error);
      setLoading(false); // Ensure loading is stopped even if there's an error
      setStars([]); // Clear stars even if there's an error
    }
  };  

  /**
   * 下载按钮事件
   */
  const handleDownloadClick = (isHighDefinition) => {
	if (!selectedImage) return;

    if (imageRef.current) {
      if (isHighDefinition) {
        // console.log("下载 [高清] image Info: ", imagePixelInfo);
        drawCanvasAndDownload(imagePixelInfo.width, imagePixelInfo.height);
      } else {
        var previewImage = document.getElementById('previewImage');
        // console.log("下载 [非高清] image Info: ", previewImage);
        drawCanvasAndDownload(previewImage.width, previewImage.height);
      }
    } else {
      alert('没有可下载的图片');
    }
  };

  const drawCanvasAndDownload = (width, height) => {
    var previewImage = document.getElementById('previewImage');
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');

    // Set canvas dimensions
    canvas.width = width;  // 设置 Canvas 的宽度
    canvas.height = height; // 设置 Canvas 的高度

    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // If no background image, just draw the preview image
    ctx.drawImage(previewImage, 0, 0, canvas.width, canvas.height);

    // Convert canvas to Blob
    canvas.toBlob(function(blob) {
      // Create a temporary link to trigger the download
      var link = document.createElement('a');

      const imageName = downloadedImageName();
      link.download = imageName + '.png';

      // Create a URL for the Blob
      link.href = URL.createObjectURL(blob);

      // Append the link to the body
      document.body.appendChild(link);

      // Simulate a click on the link to trigger the download
      link.click();

      // Remove the link from the body
      document.body.removeChild(link);
    }, 'image/png');
  };

  	const colors = [
		'#9F0500', '#FFFFFF', '#000000', '#4D4D4D', '#999999',  '#F44E3B', '#FE9200', '#FCDC00',
		'#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF',
		'#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400',
		'#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF',
		'#666666', '#B3B3B3',  '#C45100', '#FB9E00',
		'#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E'
	];

  const PixelInfo = (isHighDefinition) => {
    if (isHighDefinition) {
      if (imagePixelInfo) {
        const width = imagePixelInfo.width;
        const height = imagePixelInfo.height;
        const info = width + ' x ' + height;
        return info;
      }  
    } else {
      var previewImage = document.getElementById('previewImage');
      if (previewImage == null) return '';
      const width = previewImage.width;
      const height = previewImage.height;
      const info = width + ' x ' + height;
      return info;
    }
  }

  const handleColorChange = (color) => {
    setBackgroundColor(color);
  };

	// Add this function to handle hex input changes
	const handleHexInputChange = (e) => {
		const value = e.target.value;
		setHexInput(value);
		// Validate and update color when input is a valid hex
		if (/^[0-9A-F]{6}$/i.test(value)) {
			handleColorChange("#" + value);
		}
	};

	// // Add this function to handle hex input blur
	// const handleHexInputBlur = () => {
	// 	if (!/^#[0-9A-F]{6}$/i.test(hexInput)) {
	// 		setHexInput(backgroundColor);
	// 	}
	// };

  const handleUploadClick = () => {
    document.getElementById('fileInput-removebg').click();
  };
  const UploadImageOnChange = (e) => {
    const newImage = e.target.files[0];
    if (newImage) {
      handleUploadImage(newImage);
    }
  }
  
  return (
    <div className='flex flex-col h-screen bg-white'>
    	<div className='flex items-center justify-center gap-10 w-full h-screen px-10 mt-2 bg-white'>
			<div className="items-center justify-center w-full md:w-1/2 gap-4">
				<div className="min-h-[400px] w-[80%] p-4 border border-border rounded-lg relative overflow-hidden flex items-center justify-center bg-white">
					<div ref={imageRef} id="previewImageContainer" style={{ height: '100%', width: '100%', backgroundColor: selectedImage ? backgroundColor : 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					{selectedImage ? (
						<img
						id="previewImage"
						src={selectedImage}
						alt="Selected"
						style={{ maxWidth: '100%', maxHeight: '100%', height: 'auto' }}
						/>
					) : (
						<Button className="flex items-center justify-center" onClick={handleUploadClick} style={{ cursor: 'pointer', zIndex: 10 }}>上传图片</Button>
					)}
					</div>
					
					<input
					id="fileInput-removebg"
					type="file"
					accept="image/*"
					onClick={(event) => {event.target.value = null}}
					onInput={UploadImageOnChange}
					style={{ display: 'none' }}
					/>
					
					<StarLoading loading={loading} stars={stars} style={{ zIndex: 5 }} />
				</div>
			</div>

			<div className='bottom-toolbar'>
				<div className='color-picker'>
					{colors.map((color) => (
					<div 
						key={color}
						className={`color-box ${backgroundColor === color ? 'active' : ''}`}
						style={{ backgroundColor: color }}
						onClick={() => handleColorChange(color)}
					/>
					))}
				</div>
			</div>

			<div className="download-buttons">
				<div>需要其他颜色?</div>
				<div className='hex-input-container'>
				<span className='hex-label'>#</span>
				<input
					type="text"
					value={hexInput.replace('#', '')}
					onChange={handleHexInputChange}
					// onBlur={handleHexInputBlur}
					className='hex-input'
					placeholder='输入色值'
					maxLength={6}
				/>
				</div>

				<div className="flex flex-col gap-2">
				<Button className="download-btn" onClick={() => handleDownloadClick(false)}>
					下载普通版
				</Button>
				<div className='flex justify-center'>{PixelInfo(false)}</div>
				</div>

				<div className="flex flex-col gap-2">
				<Button className="download-btn" onClick={() => handleDownloadClick(true)}>
					下载高清版
				</Button>
				<div className='flex justify-center'>{PixelInfo(true)}</div>
				</div>
			</div>









		</div>
    </div>  
  );
};

export default ReplaceBackgroundColorPage;