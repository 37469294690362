import React from 'react';
import { HuanbjtLicense } from './License';
import '../css/License.css';

function Footer() {
  return (
    <footer className="footer" backgroundColor="white" style={{
      width: "100%",
      marginTop: "32px",
    }}>
      <div style={{
        width: '100%',
        height: '100px',
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '32px',
      }}>
        <div style={{ width: '80%', height: '.1px', backgroundColor: 'gray' }} />
      </div>

      <div className="footer-content" style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        backgroundColor: 'white',
      }}>
        <div className="footer-section">
          <h4 style={{
            justifyItems: 'flex-start',
            fontWeight: 'bold',
          }}>工具集</h4>
          <ul>
            <li style={{
              color: 'black',
              fontSize: '.875rem',
              lineHeight: '1.25rem',
              marginTop: '16px',
            }}>更换背景颜色</li>
            <li style={{
              color: 'black', 
              fontSize: '.875rem',
              lineHeight: '1.25rem',
              marginTop: '5px',
            }}>消除背景</li>
            <li style={{
              color: 'black',
              fontSize: '.875rem',
              lineHeight: '1.25rem', 
              marginTop: '5px',
            }}>添加背景文字</li>
            <li style={{
              color: 'black',
              fontSize: '.875rem',
              lineHeight: '1.25rem',
              marginTop: '5px',
            }}>模糊背景</li>
            <li style={{
              color: 'black',
              fontSize: '.875rem',
              lineHeight: '1.25rem',
              marginTop: '5px',
            }}>替换背景图片</li>
            <li style={{
              color: 'black',
              fontSize: '.875rem',
              lineHeight: '1.25rem',
              marginTop: '5px',
            }}>局部擦除替换</li>
            <li style={{
              color: 'black',
              fontSize: '.875rem',
              lineHeight: '1.25rem',
              marginTop: '5px',
            }}>图片创作</li>
          </ul>
        </div>

        <div className="footer-section">
          <h4 style={{
            justifyItems: 'flex-start',
            fontWeight: 'bold',
          }}>文档</h4>
          <ul>
            <li style={{
              color: 'black',
              fontSize: '.875rem',
              lineHeight: '1.25rem',
              marginTop: '16px',
            }}>API文档</li>
          </ul>
        </div>

        <div className="footer-section">
          <h4 style={{
            justifyItems: 'flex-start',
            fontWeight: 'bold',
          }}>关于</h4>
          <ul>
            <li style={{
              color: 'black',
              fontSize: '.875rem',
              lineHeight: '1.25rem',
              marginTop: '16px',
            }}><a href='/about-us' style={{textDecoration: 'none', color: 'black'}}>博客</a></li>
            <li style={{
              color: 'black',
              fontSize: '.875rem',
              lineHeight: '1.25rem',
              marginTop: '5px',
            }}><a href='/about-us' style={{textDecoration: 'none', color: 'black'}}>关于我们</a></li>
          </ul>
        </div>
      </div>

      <div style={{
        width: '100%',
        height: '100px',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <div style={{ width: '100%', height: '0px', backgroundColor: 'currentcolor' }} />
      </div>

      <div className="footer-bottom" style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'white',
        color: '#fff',
        padding: '10px',
      }}>
        <HuanbjtLicense />
      </div>
    </footer>
  );
}

export default Footer;