import React, { useEffect, useRef, useState } from 'react';
import { PlusIcon, ReloadIcon } from '@radix-ui/react-icons';
import { ScrollArea } from '../components/ui/scroll-area';
import { Accordion } from '../components/ui/accordion';
import { Button } from '../components/ui/button';
import TextCustomizer from '../components/editor/text-customizer';
import { downloadedImageName } from '../utils/Utils';
import { useLocation } from 'react-router-dom';
import '../css/fonts.css';

const AddBackgroundTextPage = () => {
    const [isImageSetupDone, setIsImageSetupDone] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [removedBgImageUrl, setRemovedBgImageUrl] = useState(null);
    const [textSets, setTextSets] = useState([]);
    const canvasRef = useRef(null);
    const fileInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false); // 新增加载状态

    const location = useLocation();
    let data = location.state; // 获取传递的数据

    useEffect(() => {
        window.scrollTo(0, 0);
        
        if (data) {
            setSelectedImage(URL.createObjectURL(data));

            uploadImage(data);
        }
    }, [data])

    const uploadImage = async (imageFile) => {    
        setIsLoading(true); // 开始加载
  
        // Create form data with the image file
        const formData = new FormData();
        formData.append('image', imageFile);
      
        try {
          // Upload image to server
          const response = await fetch('/api/remove-bg', {
            method: 'POST',
            body: formData,
          });
      
          // Check if the response status is OK (status in the range 200-299)
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
      
          // Convert response to a blob
          const blob = await response.blob();      
          // Create a URL for the blob and set the selected image
          const url = URL.createObjectURL(blob);

          setRemovedBgImageUrl(url);
          setIsImageSetupDone(true);    
        } catch (error) {
          // Handle errors
          console.error('Error uploading image:', error);
        } finally {
            setIsLoading(false); // 上传完成，停止加载
        }
      };     

    const handleUploadImage = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = async (event) => {
        setIsImageSetupDone(false);
        setRemovedBgImageUrl(null);
        if (location.data) {
            location.data = null;
        }

        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
      
            await uploadImage(file);
        }
    };

    const addNewTextSet = () => {
        const newId = Math.max(...textSets.map(set => set.id), 0) + 1;
        setTextSets(prev => [...prev, {
            id: newId,
            text: '文案',
            fontFamily: 'Inter',
            top: 0,
            left: 0,
            color: 'white',
            fontSize: 200,
            fontWeight: 800,
            opacity: 1,
            shadowColor: 'rgba(0, 0, 0, 0.8)',
            shadowSize: 4,
            rotation: 0
        }]);
    };

    const handleAttributeChange = (id, attribute, value) => {
        setTextSets(prev => prev.map(set => 
            set.id === id ? { ...set, [attribute]: value } : set
        ));
    };

    const duplicateTextSet = (textSet) => {
        const newId = Math.max(...textSets.map(set => set.id), 0) + 1;
        setTextSets(prev => [...prev, { ...textSet, id: newId }]);
    };

    const removeTextSet = (id) => {
        setTextSets(prev => prev.filter(set => set.id !== id));
    };

    const saveCompositeImage = () => {
        if (!canvasRef.current || !isImageSetupDone) return;
    
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;
    
        const bgImg = new Image();
        bgImg.crossOrigin = "anonymous";
        bgImg.onload = () => {
            canvas.width = bgImg.width;
            canvas.height = bgImg.height;
    
            ctx.drawImage(bgImg, 0, 0, canvas.width, canvas.height);
    
            textSets.forEach(textSet => {
                ctx.save(); // Save the current state
                // Adjust font size for download to match display size
                const aspectRatio = bgImg.width / bgImg.height;

                const displayFontSize = textSet.fontSize * aspectRatio; // Adjust this multiplier as needed
                ctx.font = `${textSet.fontWeight} ${displayFontSize}px ${textSet.fontFamily}`;
                ctx.fillStyle = textSet.color;
                ctx.globalAlpha = textSet.opacity;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';

                const x = canvas.width * (textSet.left + 50) / 100;
                const y = canvas.height * (50 - textSet.top) / 100;
    
                // Move the context to the text position
                ctx.translate(x, y);
                // Rotate the context
                ctx.rotate((textSet.rotation * Math.PI) / 180); // Convert degrees to radians
                
                // Draw text at the origin (0, 0)
                ctx.fillText(textSet.text, 0, 0); 
                ctx.restore(); // Restore the original state
            });
    
            if (removedBgImageUrl) {
                const removedBgImg = new Image();
                removedBgImg.crossOrigin = "anonymous";
                removedBgImg.onload = () => {
                    ctx.drawImage(removedBgImg, 0, 0, canvas.width, canvas.height);
                    triggerDownload();
                };
                removedBgImg.src = removedBgImageUrl;
            } else {
                triggerDownload();
            }
        };
        bgImg.src = selectedImage || '';
    
        function triggerDownload() {
            const dataUrl = canvas.toDataURL('image/png');
            const link = document.createElement('a');

            const imageName = downloadedImageName();
            link.download = imageName + '.png';
            link.href = dataUrl;
            link.click();
        }
    };

    return (
        <div className='flex flex-col h-screen bg-white'>
            <div className='flex flex-col md:flex-row items-start justify-start gap-10 w-full h-screen px-10 mt-16'>
                <div className="flex flex-col items-start justify-start w-full md:w-1/2 gap-4">
                    <canvas ref={canvasRef} style={{ display: 'none' }} />
                    <div className='flex gap-4'>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            accept=".jpg, .jpeg, .png"
                        />
                        <Button onClick={handleUploadImage}>
                            上传图片
                        </Button>
                        <Button onClick={saveCompositeImage}>
                            下载图片
                        </Button>
                    </div>
                    <div className="min-h-[400px] w-[80%] p-4 border border-border rounded-lg relative overflow-hidden">
                    {isLoading ? ( // 根据加载状态渲染
                            <span className='flex items-center w-full gap-2'>
                                <ReloadIcon className='animate-spin' /> 加载中...
                            </span>
                        ) : isImageSetupDone ? (
                            <img
                                src={selectedImage} 
                                alt="Uploaded"
                                layout="fill"
                                objectFit="contain" 
                                objectPosition="center" 
                            />
                        ) : null}
                        {isImageSetupDone && textSets.map(textSet => (
                            <div
                                key={textSet.id}
                                style={{
                                    position: 'absolute',
                                    top: `${50 - textSet.top}%`,
                                    left: `${textSet.left + 50}%`,
                                    transform: `translate(-50%, -50%) rotate(${textSet.rotation}deg)`,
                                    color: textSet.color,
                                    textAlign: 'center',
                                    fontSize: `${textSet.fontSize}px`,
                                    fontWeight: textSet.fontWeight,
                                    fontFamily: textSet.fontFamily,
                                    opacity: textSet.opacity
                                }}
                            >
                                {textSet.text}
                            </div>
                        ))}
                        {removedBgImageUrl && (
                            <img
                                src={removedBgImageUrl}
                                alt="Removed bg"
                                layout="fill"
                                objectFit="contain" 
                                objectPosition="center" 
                                className="absolute top-0 left-0 w-full h-full"
                            /> 
                        )}
                    </div>
                </div>
                <div className='flex flex-col w-full md:w-1/2'>
                    <Button variant={'secondary'} onClick={addNewTextSet}><PlusIcon className='mr-2'/>文字编辑器</Button>

                    <ScrollArea className="h-[calc(100vh-10rem)] p-2">
                        <Accordion type="single" collapsible className="w-full mt-2">
                            {textSets.map(textSet => (
                                <TextCustomizer 
                                    key={textSet.id}
                                    textSet={textSet}
                                    handleAttributeChange={handleAttributeChange}
                                    removeTextSet={removeTextSet}
                                    duplicateTextSet={duplicateTextSet}
                                />
                            ))}
                        </Accordion>
                    </ScrollArea>
                </div>
            </div>
        </div>
    );
};

export default AddBackgroundTextPage;