'use client'

import React from 'react';
import { Label } from '../ui/label';
import { Slider } from '../ui/slider';
import { Input } from '../ui/input';     

const SliderField = ({
    attribute,
    label,
    min,
    max,
    step,
    currentValue,
    handleAttributeChange
  }) => { 
    const handleSliderInputFieldChange = (event) => {
      const value = parseFloat(event.target.value);
      handleAttributeChange(attribute, value);
    };
  
    return (
      <>
        <div className="flex items-center justify-between mt-8">
          <Label htmlFor={attribute}>{label}</Label>
          <Input
            type="text"
            value={currentValue}
            onChange={handleSliderInputFieldChange}
            className="w-12 rounded-md border border-gray-300 px-2 py-0.5 text-center text-sm text-muted-foreground hover:border-gray-400 hover:text-gray-800 hover:animate-pulse"
            min={min}
            max={max}
            step={step}
          />
        </div>
        <Slider
          id={attribute}
          min={min}
          max={max}
          value={[currentValue]}
          step={step}
          onValueChange={(value) => handleAttributeChange(attribute, value[0])}
          className="[&_[role=slider]]:h-4 [&_[role=slider]]:w-4 mt-2"
          aria-label={label}
        />
      </>
    );
};

export default SliderField