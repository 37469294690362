import React from 'react';
import { AppBar, Toolbar, IconButton, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { HUANBJT_LOGO } from '../utils/Constant';
import { Button as CustomButton } from './ui/button';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { styled } from '@mui/system';

const Header = () => {
    const navigate = useNavigate();
    const [anchor, setAnchor] = React.useState(null);
  
    const open = Boolean(anchor);
    const id = open ? 'simple-popper' : undefined;
  
    const handleBackClick = () => {
        navigate('/'); // 返回首页
    };

    const handleReplaceBackgroundColor = () => {
        navigate('/replace-backgroundcolor', { state: { imageFile: null, requested: false } });
    }

    // 列出具体的工具列表供用户选择
    const handleOtherFunctions = (event) => {
        setAnchor(anchor ? null : event.currentTarget);
    }

    const handleEditAppClick = (event) => {
        setAnchor(anchor ? null : event.currentTarget);

        const buttonId = event.target.id;
        navigate(buttonId);
    }

    const handleAboutUsClick = () => {
        navigate('/about-us');
    }
    // 进入app.huanbjt.cn 进行高级创作: app.huanbjt.cn
    // const handleEditorAppClick = () => {
    //     window.open('https://app.huanbjt.cn', '_blank'); // 在新标签页中打开链接
    // }

    return (
        <AppBar position="sticky" sx={{ backgroundColor: 'white', zIndex: 50, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
            <Toolbar sx={{ height: '80px', px: { xs: 4, sm: 6, md: 8 } }}>
                <div style={{ maxWidth: '1200px', margin: '0 auto', width: '100%' }}>
                    {/* Desktop Navigation */}
                    <div className="hidden lg:flex justify-between items-center">
                        {/* Logo */}
                        <div>
                            <IconButton onClick={handleBackClick} sx={{
                                ':hover': {
                                    bgcolor: 'transparent', // theme.palette.primary.main
                                    color: 'white',
                                },
                            }}>
                                <img src={HUANBJT_LOGO} alt="Logo" style={{ width: '160px' }} />
                            </IconButton>
                        </div>

                        {/* Navigation Links */}
                        <div className="flex gap-2">
                            <Button 
                                variant="text" 
                                sx={{ color: '#000', fontSize: '1.1rem' }} 
                                onClick={handleReplaceBackgroundColor}
                            >
                                更换背景
                            </Button>
                            
                            <Button 
                                variant="text" 
                                sx={{ 
                                    color: '#000', 
                                    fontSize: '1.1rem', 
                                    background: '#FFA500', 
                                    '&:hover': {
                                       background: '#FFA500', // Keep the same background color on hover
                                       transform: 'scale(1.05)'
                                    } 
                                }} 
                                onClick={handleOtherFunctions}
                            >
                                功能
                            </Button>

                            {/* <Button>商业</Button> */}
                            {/* <Button>资源</Button> */}
                            {/* <Button onClick={() => navigate('/pricing')}>定价</Button> */}

                        </div>

                        <BasePopup id={id} open={open} anchor={anchor} disablePortal={true}>
                            <PopupBody>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', minWidth: '160px' }}>
                                <Button 
                                    id='/remove-background'
                                    variant="text" 
                                    sx={{ color: '#000', fontSize: '1.1rem', justifyContent: 'flex-start' }} 
                                    onClick={handleEditAppClick}
                                >
                                    消除背景
                                </Button>

                                <Button 
                                    id='/add-background-text'
                                    variant="text" 
                                    sx={{ color: '#000', fontSize: '1.1rem', justifyContent: 'flex-start' }} 
                                    onClick={handleEditAppClick}
                                >
                                    添加背景文字
                                </Button>

                                <Button 
                                    id='/blur-background'
                                    variant="text" 
                                    sx={{ color: '#000', fontSize: '1.1rem', justifyContent: 'flex-start' }} 
                                    onClick={handleEditAppClick}
                                >
                                    模糊背景
                                </Button>
{/* 
                                <Button 
                                    id='/replace-backgrund'
                                    variant="text" 
                                    sx={{ color: '#000', fontSize: '1.1rem', justifyContent: 'flex-start' }} 
                                    onClick={handleEditAppClick}
                                >
                                    替换背景图片
                                </Button>

                                <Button 
                                    id='/erase-backgrund-part'
                                    variant="text" 
                                    sx={{ color: '#000', fontSize: '1.1rem', justifyContent: 'flex-start' }} 
                                    onClick={handleEditAppClick}
                                >
                                    局部擦除替换
                                </Button> */}

                            </div>
                            </PopupBody>
                        </BasePopup>

                        {/* Auth Buttons */}
                        <div className="flex gap-2">
                            <CustomButton onClick={handleAboutUsClick}>关于我们</CustomButton>
                        </div>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Header;

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  
  const blue = {
    200: '#99CCFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0066CC',
  };
  
  const PopupBody = styled('div')(
    ({ theme }) => `
    width: max-content;
    padding: 12px 16px;
    margin: 8px;
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    box-shadow: ${
      theme.palette.mode === 'dark'
        ? `0px 4px 8px rgb(0 0 0 / 0.7)`
        : `0px 4px 8px rgb(0 0 0 / 0.1)`
    };
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    z-index: 1;
  `,
  );